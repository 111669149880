$(function() {

  // 以下為 Cookies 記錄在當前瀏覽器僅顯示一世
  if(!sessionStorage.getItem("agreeterm")){
    $('.cookies').stop(true).addClass('display');
  }

  $(".close-cookies").on('click',function(e) {
    e.preventDefault();
    sessionStorage.setItem("agreeterm", true);
    $(".cookies").addClass("close-cookies");
  });

  // 以下為Swiper設定
 
   // 共用Auto 
   var auplayFun = {
     delay: 3500,
     //stopOnLastSlide: false,
     disableOnInteraction: false,
   }
 
   // 首頁Banner
   if($('.swiper-bannerIn').length > 0) {
     var swiper_bannerIn = new Swiper(".swiper-bannerIn .swiper-container", {
      // direction: "horizontal",
      loop: true,
      centeredSlides : true,
      slidesPerView:'auto',
      navigation: {
        nextEl: '.swiper-bannerIn .swiper-button-next',
        prevEl: '.swiper-bannerIn .swiper-button-prev',
      },
      grabCursor: true,
      speed: 1000,
      pagination: '.swiper-bannerIn .swiper-pagination',
      paginationClickable: true,
      // parallax: true,
      // effect: "slide",
      reverseDirection: true,

      // autoplay: {
      //   reverseDirection: true,
      // },
      autoplay: false, //auplayFun
      // mousewheelControl: 1,
     });
   }

  //  首頁影片
  var indexVideoSwiper = new Swiper('.index-pageVideo-swiper .swiper-container', {
	  speed: 2000,
    navigation: {
      nextEl: '.index-pageVideo-swiper .swiper-button-next',
      prevEl: '.index-pageVideo-swiper .swiper-button-prev',
    },
  });

  // 首頁Explore 
  var indexExploreSwiper = new Swiper('.index-pageExplore-swiper .swiper-container', {
    slidesPerView: 3,
    spaceBetween: 30,
	  speed: 2000,
    navigation: {
      nextEl: '.index-pageExplore-swiper .swiper-button-next',
      prevEl: '.index-pageExplore-swiper .swiper-button-prev',
    },

    breakpoints: { 
      320: {  //当屏幕宽度大于等于320
        slidesPerView: 1,
        spaceBetween: 5
      },
      768: {  //当屏幕宽度大于等于768 
        slidesPerView: 3,
        spaceBetween: 20
      },
      1280: {  //当屏幕宽度大于等于1280
        slidesPerView: 3,
        spaceBetween: 30,
      }
    }

  });
   
  // 打開搜尋bar
  $('.toggle-search').on('click',function() {
    $('.search-formList').toggleClass('open');
    return false;
  });

  // 
  $('.list-group-mobile-btn').on('click',function(){
    $('.link-horizontal-wrap').toggleClass('open');
    return false;
  })
 
   // 以下為Gotop
  $(".float-link .link-btn.top, .float-link .link-btn.mobile-top").on('click',function () {
    $("html,body").animate({ "scrollTop": "0" })
  });
 
   $(window).on("load resize scroll", function (e) {
     var scroll = $(this).scrollTop();
     var wdh = $(window).height();
     if (scroll > 50) {
         $(".float-link").addClass("active");
     } else {
         $(".float-link").removeClass("active");
     }
   });

   // BS navbar-collapse show / hidden
   $('.navbar-toggler').on('click',function(){
    $('.navbar-overlay, .navbar-toggler.fixed').addClass('active')
  })
 
  $('#js-navbarToggle-fixed').on('click', function () {
    $('#navbar-content').removeClass('show');
    $('.navbar-overlay, .navbar-toggler.fixed').removeClass('active')
  });
 
   // 文字點點點
   checkEllipsis('.jsEllipsis',50);
 });
 
 function resize() {
   var winHeight = $(window).height();
   $(".modal-autoheight .modal-body").css({
     width: "auto",
     height: (winHeight - 200) + "px"
   });
 }
 
 // 取得頁面寬度
 function viewport() {
   var e = window,
       a = 'inner';
   if (!('innerWidth' in window)) {
       a = 'client';
       e = document.documentElement || document.body;
   }
   return { width: e[a + 'Width'], height: e[a + 'Height'] };
 }
 
 // 文字點點點
 function checkEllipsis(name,len){
   $(name).each(function(i){
     if($(this).text().length>len){
         $(this).attr("title",$(this).text());
         let text=$(this).text().substring(0,len-1)+"...";
         $(this).text(text);
     }
   });
 }
 